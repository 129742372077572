/*******************
customizer
*******************/
.customizer {
  background: $theme-light;
  position: fixed;
  height: 100%;
  width: 280px;
  top: 0px;
  right: -280px;
  z-index: 100;
  box-shadow: $shadow;
  transition: 0.3s ease-in;
  .service-panel-toggle {
    background: $danger;
    padding: 12px 20px;
    color: $white;
    position: absolute;
    top: 30%;
    left: -57px;
  }
  &.show-service-panel {
    right: 0px;
  }
  .customizer-body {
    position: relative;
    height: 100%;
  }
  .customizer-tab {
    display: flex;
    .nav-item {
      width: 33.33%;
      text-align: center;
      .nav-link {
        padding: 15px 12px;
        color: $body-color;
        border-bottom: 3px solid transparent;
        &:hover,
        &.active {
          border-bottom: 3px solid $themecolor;
          color: $themecolor;
        }
      }
    }
  }
}
.custom-control-label::after,
.custom-control-label::before {
  top: 0.15rem;
}

.theme-color {
  padding: 0px;
  margin: 0px;
  list-style: none;
  .theme-item {
    list-style: none;
    display: inline-block;
    margin-right: 5px;
    .theme-link {
      border-radius: 100%;
      width: 20px;
      height: 20px;
      display: block;
      &[data-logobg='skin1'],
      &[data-navbarbg='skin1'],
      &[data-sidebarbg='skin1'] {
        background: $skin1;
      }
      &[data-logobg='skin2'],
      &[data-navbarbg='skin2'],
      &[data-sidebarbg='skin2'] {
        background: $skin2;
      }
      &[data-logobg='skin3'],
      &[data-navbarbg='skin3'],
      &[data-sidebarbg='skin3'] {
        background: $skin3;
      }
      &[data-logobg='skin4'],
      &[data-navbarbg='skin4'],
      &[data-sidebarbg='skin4'] {
        background: $skin4;
      }
      &[data-logobg='skin5'],
      &[data-navbarbg='skin5'],
      &[data-sidebarbg='skin5'] {
        background: $skin5;
      }
      &[data-logobg='skin6'],
      &[data-navbarbg='skin6'],
      &[data-sidebarbg='skin6'] {
        background: $gray-200;
      }
    }
  }
}
