$xs: 576 !default;
$lg: 992 !default;
$xl: 1200 !default;
$xlg: 1400 !default;

$white: #fff !default;
$black: #000 !default;
$background-gray: #f0f0f0 !default;
$light-gray: #cbd3d9 !default;
$tutt-gray: #afb4b7 !default;
$gray: #a4a7a9 !default;
$midGray: #808a8b !default;
$dark-gray: #707070 !default;
$blue-light: #dbe3f4 !default;
$blue-gray: #d3d2e1 !default;
$blue-dark: #4b5b78 !default;
$onsite: #0095b7 !default;
$blue: #1e7fc3 !default;
$new: #a63ace !default;
$checkup: #a63ace !default;
$purple: #9695b6 !default;
$remote: #9db9e1 !default;
$light-teal: #c5dfe3;
$preventive: #44477a !default;
$closed: #00c479 !default;
$fixed: #00c479 !default;
$cycle: #93e33c !default;
$tuttred: #ed1944 !default;
$red-light: #f9c3b8 !default;

:export {
  backgroundGray: $background-gray;
  black: $black;
  lightGray: $light-gray;
  midGray: $midGray;
  gray: $gray;
  darkGray: $dark-gray;
  tuttGray: $tutt-gray;
  blue: $blue;
  lightBlue: $blue-light;
  BlueGray: $blue-gray;
  onsite: $onsite;
  purple: $purple;
  preventive: $preventive;
  purpleNew: $new;
  purpleCheckup: $checkup;
  closed: $closed;
  fixed: $fixed;
  cycle: $cycle;
  tuttred: $tuttred;
  redLight: $red-light;
  remote: $remote;
  lightTeal: $light-teal;
  darkBlue: $blue-dark;
  white: $white;
}

@font-face {
  font-family: 'tutt-regular';
  src: local('TitilliumWeb-Regular'), url('./../../../fonts//TitilliumWeb-Regular.ttf') format('truetype');
}
