.modal_dialog {
  max-width: unset !important;
  margin-right: 1vw !important;
  margin-left: 1vw !important;
  margin-top: 1vh !important;
  margin-bottom: 1vh !important;
}

.keyboard_modal {
  height: 80% !important;
}
