@import './customStyles/variable.module.scss';

#loginform {
  padding: 70px;
}

.navbarbg {
  background-color: #ffffff !important;
}

.header-light-dark-mode {
  margin-top: 25px;
  margin-right: 30px;
}

.header-profile-name {
  padding-top: 18px;
  color: #15102e;
}

.header-profile-arrow {
  font-size: 14px;
  margin-top: 10px;
}

.header-profile-popup-color {
  background-color: #272c33;
}

.profile-dd {
  display: none;
}

#sidebarnav {
  background-color: #fff !important;
  padding: 25px 0 25px 0;
}

#sidebarnav .active {
  background: none !important;
  box-shadow: none;
  background-color: transparent !important;
  color: #32d6d0 !important;
}

#sidebarnav .sidebar-item {
  font-size: 14px;
}

#sidebarnav .sidebar-link {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar-nav ul .sidebar-item .sidebar-link:hover {
  color: #32d6d0 !important;
}

.rectangle {
  width: 100%;
  height: 70px;
  position: relative;
  background-color: #272c33;
}

.rectangle-round-down {
  width: 100%;
  height: 70px;
  background-color: #e0ebf8;
  border-radius: 0px 0px 0px 70px;
}

[dir='rtl'] .rectangle-round-down {
  border-radius: 0px 0px 70px 0px;
}

.navbarbg {
  background-color: #272c33 !important;
  height: 70px;
}

.round-corner-up-bg {
  background-color: #272c33 !important;
  width: 70px;
}

.round-corner-up {
  height: 70px;
  background-color: #272c33;
  border-radius: 70px 0px 0px 0px;
}

[dir='rtl'] .round-corner-up {
  border-radius: 0px 70px 0px 0px !important;
}

.navbar-nav {
  background-color: #272c33;
}

.footer {
  padding: 0px;
  margin: 0px;
  margin-bottom: -45px;
}

.image-modal-card {
  padding: 20px;
}

.modal-content {
  border-width: 0;
  border-radius: 0.375rem;
}

.cursor-pointer {
  cursor: pointer;
}

.ReactTable {
  border: unset !important;
}

.ReactTable .rt-thead .rt-th {
  border-right: unset !important;
  text-align: left;
  padding-right: 5px !important;
  padding-left: 5px !important;
  position: relative;
}

.ReactTable .rt-thead.-header {
  box-shadow: none !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead .rt-resizable-header-content {
  font-size: 14px;
  color: #164669;
  font-weight: bold;
  text-align: left;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  margin-top: auto;
  margin-bottom: auto;
}

.ReactTable.-striped .expand-icon {
  opacity: 0.5;
}

.ReactTable.-striped .rt-tr:hover {
  .expand-icon {
    opacity: 1;
  }
}

.ReactTable.-striped .rt-tr.-odd[role='row'] {
  background: transparent;
}

.ReactTable.-striped .rt-tr.-even {
  background-color: rgba(0, 0, 0, 0.03);
}

.ReactTable .rt-tbody .rt-td {
  min-height: 45px;
  margin: 0 0;
  place-items: center;
  font-size: 14px;
  color: #262d3c;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  display: flex;
  border-right: unset !important;
  padding: 7px 10px;
}

.ReactTable .header-content {
  column-gap: 5px;
  font-size: 16px;
  font-weight: bold;
  color: $blue-dark;
  white-space: normal;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  div:not(.sortedIcon),
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .sortedIcon {
    color: $remote;
    font-size: 7px;
    display: flex;
    flex-direction: column;
    line-height: 0.97;
    margin-top: auto;
    margin-bottom: auto;

    .sortedIconDown {
      transform: rotate(180deg);
    }

    &.asc {
      .sortedIconUp {
        color: $blue-dark;
      }
    }

    &.desc {
      .sortedIconDown {
        color: $blue-dark;
      }
    }
  }
}

.ReactTable .resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 3px;
  background: $gray;
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: transparent;
  border-right: 4px $dark-gray solid;
  opacity: 1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
    background: transparent;
    border-right: 2px $dark-gray solid;
  }
}

.card {
  border-radius: 6px;
  border: solid 1.1px #e6e5f2;
  box-shadow: none;
}

.table-title {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.16px;
  color: #272e50;
}

.device-sidebar {
  width: 400px;
  right: -400px;
}

.device-control {
  width: 100%;
}

.device-control .input-label {
  font-size: 18px;
  font-weight: bold;
}

.device-control .slider-number {
  font-weight: bold;
}

.device-class-card {
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.14px;
  text-align: center;
  color: #262d3c;
}

.device-class-card .logo {
  width: 86px;
  margin-bottom: 20px;
}

.button-big {
  width: 180px;
  height: 45px;
  margin: 20px 0 0;
  border-radius: 4px;
  border: solid 1px #1b7389;
  color: #1b7389;
  background-color: #ffffff;
}

.device-class-card .devices-in-project {
  padding-top: 5px;
  font-family: Heebo;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.11px;
  text-align: center;
  color: #262d3c;
}

.table-loading {
  position: absolute;
  top: 100px;
  left: 100px;
}

.project-page h2 {
  margin-left: 5px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.device-data b {
  font-size: 18px;
}

.device-data {
  font-size: 18px;
}

.device-page h2 {
  margin-left: 5px;
  margin-bottom: 10px;
  padding-top: 20px;
}

.machine-link {
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
}

.pieFlex {
  width: 320px;
  display: flex-end;
  margin: 0 auto;
  margin-top: 20px;
  flex-wrap: wrap;
  flex-direction: row;
}

.btn-secondary:hover svg {
  fill: #72859a;
}
