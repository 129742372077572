@import 'variable.module.scss';

body {
  background-color: $background-gray !important;
  font-family: tutt-regular !important;
  cursor: default;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
label,
p {
  color: $blue-dark;
}

.paddingX-10 {
  padding-right: 10px;
  padding-left: 10px;
}
